// src/pages/SignIn.js
import  { useState } from 'react';
import styled from 'styled-components';
import { OAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth } from "../config/firebase";
import { useNavigate } from 'react-router-dom';
import { showError } from '../qcp-utils';
import { GoogleAuthProvider } from 'firebase/auth';
import { FaApple, FaGoogle } from 'react-icons/fa';

const SignInWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fbfbfd;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

const SignInForm = styled.form`
  background-color: white;
  padding: 40px;
  border-radius: 18px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 380px;
`;

const Title = styled.h1`
  font-size: 27px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  color: #1d1d1f;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #d2d2d7;
  border-radius: 6px;
  font-size: 17px;
  color: #1d1d1f;
  transition: border-color 0.3s ease;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #0071e3;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #0071e3;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 17px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0077ed;
  }
  &:disabled {
    background-color: #f2f2f7;
  }
`;

const GoogleButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #4285F4;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  transition: background-color 0.3s ease;
  gap: 5px;

  &:hover {
    background-color: #357ae8;
  }
  &:disabled {
    background-color: #f2f2f7;
  }
`;

const AppleButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  transition: background-color 0.3s ease;
  gap: 5px;

  &:hover {
    background-color: #333333;
  }
  &:disabled {
    background-color: #f2f2f7;
  }
`;

const ForgotPassword = styled.a`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #0066cc;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

  const handleSignIn = async(provider) => {
    setLoading(true);
    try{
      const result = await signInWithPopup(auth, provider);
      console.log('Sign in successful:', result);
      navigate('/dashboard');
    }catch(e){
      console.error(e);
      showError({message: 'Sign in failed.'});
    }finally{
      setLoading(false);
    }
  }

  const handleAppleSignIn = async () =>  handleSignIn(
    new OAuthProvider('apple.com')
  );

  const handleGoogleSignIn = async () => handleSignIn(
    new GoogleAuthProvider()
  );
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle sign in logic here
    console.log('Sign in attempted with:', email, password);
    setLoading(true);
    try{
      var signin = await signInWithEmailAndPassword(auth, email, password);
      console.log('Sign in successful:', signin);
      navigate('/dashboard');
    }catch(e){
      console.error(e);
      showError({message: 'Sign in failed.'});
    }finally{
    setLoading(false);
    }
  };

  return (
    <SignInWrapper>
      <SignInForm onSubmit={handleSubmit}>
        <Title>Sign in to Agile</Title>
        <Input 
          type="email"
          placeholder="Email"
          disabled={loading}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          disabled={loading}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <SubmitButton type="submit" disabled={loading}>Sign In</SubmitButton>
        <GoogleButton type="button" onClick={handleGoogleSignIn} disabled={loading}>
          <FaGoogle className="h-5 w-5 mr-2 text-red-600"/>Sign in with Google
        </GoogleButton>
        <AppleButton type="button" onClick={handleAppleSignIn} disabled={loading}>
          <FaApple className="h-5 w-5 mr-2 text-white"/>Sign in with Apple
        </AppleButton>
        <ForgotPassword href="#">Forgot password?</ForgotPassword>
      </SignInForm>
    </SignInWrapper>
  );
}

export default SignIn;