import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #1d1d1f;
  background-color: #fbfbfd;
  padding: 80px 20px;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
`;

const TableOfContents = styled.div`
  background-color: #f5f5f7;
  border-radius: 18px;
  padding: 30px;
  margin-bottom: 40px;
`;

const TOCTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const TOCList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TOCItem = styled.li`
  margin-bottom: 10px;
`;

const TOCLink = styled.a`
  color: #06c;
  text-decoration: none;
  font-size: 17px;

  &:hover {
    text-decoration: underline;
  }
`;

const Chapter = styled.div`
  margin-bottom: 60px;
`;

const ChapterTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
`;

const Article = styled.div`
  margin-bottom: 40px;
`;

const ArticleTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const List = styled.ol`
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
  padding-left: 30px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

function TermsOfService() {
  const chapterRefs = useRef([]);

  const chapters = [
    { 
      id: 'chapter1', 
      title: 'Chapter 1: Welcome!',
      articles: [
        {
          title: 'Article 1 (Purpose)',
          content: 'Thank you for using the services provided by Agile Co., Ltd. (hereinafter referred to as "the Company"). The Company has created a unified login account system that allows you to conveniently access individual services provided by the Company or its affiliates. This system is governed by the \'Agile Account Terms and Conditions\' (hereinafter referred to as "these Terms"). These Terms outline your rights, obligations, responsibilities, and the conditions and procedures for using the Agile account services, so please take the time to read them carefully.'
        },
        {
          title: 'Article 2 (Effect and Changes to the Terms)',
          content: [
            'The contents of these Terms are posted on agile.place or displayed on individual service screens, and take effect for all users who agree to these Terms.',
            'The Company may amend these Terms as needed, provided that such changes do not violate relevant laws. In the event of any changes to these Terms, the Company will notify you via service announcements 15 days prior to the effective date. If the changes are disadvantageous to you, the Company will notify you at least 30 days in advance by email sent to the address registered with your Agile account (if no email is provided, via electronic messages within the service or notifications).',
            'If you do not express your refusal of the amended Terms within seven days after the notification, and the Company has clearly notified you of this consequence, you will be deemed to have accepted the amended Terms. If you do not agree to the amended Terms, you may terminate your use of the service.'
          ]
        },
        {
          title: 'Article 3 (Definitions)',
          content: [
            'Agile Account: A login account system designated by the Company that allows user authentication, management of personal information, account registration, and withdrawal for individual services provided by the Company or its affiliates using a single account and password.',
            'Member: A user who agrees to these Terms and uses the Agile account in individual services or the Agile account website.',
            'Affiliate: A legal entity that has entered into a partnership with the Company and agreed to provide the Agile account together. The list of affiliates can be viewed on agile.place and may change or expand in the future.',
            'Individual Service: Services provided by the Company or its affiliates that can be accessed using the Agile account. The services may change, and any changes will be posted on agile.place.',
            'Agile Account Website: The online site where members can view and modify their Agile account information.',
            'Agile Account Information: Mandatory or optional input information set by the Company for using the Agile account, which can be managed through the Agile account settings page on the website or within individual services.'
          ]
        }
      ]
    },
    {
      id: 'chapter2',
      title: 'Chapter 2: Agile Account Service Agreement',
      articles: [
        {
          title: 'Article 4 (Formation of Contract)',
          content: [
            'Application for using the Agile account is made by entering certain information into the Agile account registration screen on individual services or the Agile account website.',
            'The Agile account service agreement is concluded when the Company approves the membership after verifying the entered information following your acceptance of these Terms.'
          ]
        },
        {
          title: 'Article 5 (Restrictions on Use of Agile Account)',
          content: [
            'The Company generally accepts the use of the Agile account for applicants in accordance with Article 5. However, the Company may withhold or deny approval in the following cases until the issue is resolved. Particularly, if you are under 14 years old, you may only create a Agile account with the consent of a legal guardian, such as a parent:',
            'If your Agile account was previously deleted by the Company according to these Terms or other guidelines.',
            'If you attempt to create a Agile account using another person\'s name, email address, or other personal information.',
            'If you fail to provide the required information or provide false information.',
            'If there is no available capacity in the service infrastructure.',
            'If technical issues prevent the provision of the service.',
            'If the Company deems it necessary for financial or technical reasons.',
            'If you attempt to reapply during a membership suspension period.',
            'If your application violates relevant laws or the Company\'s standards.',
            'If it is found that you violated the conditions listed above when creating a Agile account, the Company may immediately suspend or delete your account.'
          ]
        }
      ]
    },
    {
      id: 'chapter3',
      title: 'Chapter 3: Use of Agile Account',
      articles: [
        {
          title: 'Article 6 (Notifications and Advertising)',
          content: 'To provide better service, the Company may send various notices, management messages, or advertisements related to the use of Agile services via the service screen or to your email. Advertisement messages will only be sent if you have agreed to receive them in advance.'
        },
        {
          title: 'Article 7 (Changes and Termination of Agile Account Service)',
          content: [
            'The Company will make its best efforts to provide the Agile account service continuously 24/7. However, the Company may restrict or suspend the service in the following cases:',
            'For regular or temporary maintenance of the Agile account service system.',
            'In the event of power outages, system malfunctions, or excessive service usage.',
            'Due to termination of agreements with affiliates, government orders/regulations, or changes in service/member policies.',
            'Due to natural disasters, national emergencies, or other uncontrollable events.',
            'In the event of service suspension under the above conditions, the Company will notify you in advance via the methods outlined in Article 14. If the suspension is due to unforeseen circumstances beyond the Company\'s control, such as a system crash or disk failure, prior notification may not be possible. In any case, the Company will make every effort to restore the service as quickly as possible.'
          ]
        },
        {
          title: 'Article 8 (Agile Account Management)',
          content: [
            'Your Agile account is for personal use only, and you must not allow others to use it. You are responsible for managing your password to prevent unauthorized use of your Agile account. You can view and modify your account information via the Agile account website or the settings page within individual services. However, some information necessary for providing and managing the Agile account, such as the account, phone number, device identification number, or other verification details, may not be modifiable or may require additional identity verification.',
            'The Company is not liable for any issues arising from your failure to update your Agile account information in a timely manner.'
          ]
        }
      ]
    },
    {
      id: 'chapter4',
      title: 'Chapter 4: Obligations of Contracting Parties',
      articles: [
        {
          title: 'Article 9 (Member\'s Obligations)',
          content: [
            'When using the Agile account service, you must not engage in any of the following activities:',
            'Providing false information during application or changes, using another member\'s Agile account or password, fraudulently using another\'s name or attempting SMS verification without the consent of the rightful owner.',
            'Damaging the reputation of others or causing harm to others.',
            'Posting obscene materials on the bulletin boards or linking to pornographic websites.',
            'Infringing the copyrights or other rights of the Company or third parties.',
            'Disseminating information, texts, graphics, or sounds that violate public order and morals.',
            'Uploading or distributing infected data containing computer viruses that may cause malfunctions or disrupt information related to Agile account services.',
            'Deliberately disrupting the operation of the Agile account service or sending unsolicited advertisements or spam against the explicit rejection of recipients.',
            'Copying, modifying, distributing, selling, transferring, lending, or offering as collateral any part of the service or software included in it without the Company\'s consent, or reverse engineering, attempting to extract the source code, or imitating, disassembling, or modifying the service in any way.',
            'Impersonating others or falsely representing relationships with others.',
            'Collecting, storing, or disclosing other members\' personal information.',
            'Distributing false information with the intent to give financial benefit to oneself or others or to harm others.',
            'Facilitating prostitution or distributing information that mediates illegal sexual activities.',
            'Continuously sending words, sounds, writings, or images that cause feelings of shame, disgust, or fear, disturbing the recipient\'s daily life.',
            'Transmitting or posting information (including computer programs) prohibited by relevant laws.',
            'Pretending to be an employee or operator of the Company or an affiliate, or posting messages or sending emails, Agile messages, etc., while impersonating someone else or using their identity.',
            'Posting or sending materials via email or Agile messages containing software viruses or other malicious computer code, files, or programs designed to interfere with, destroy, or impair the normal operation of computer software, hardware, or telecommunications equipment.',
            'Engaging in any other illegal activities.',
            'You may not transfer or assign your service usage rights or any contractual status to others, nor offer them as collateral.',
            'If you violate the relevant laws, the Company\'s terms, or policies, the Company may investigate your violations and temporarily or permanently suspend your account and service usage. In severe cases that affect service stability or disrupt operations, the Company may impose restrictions on your accounts involved in these risky activities. If you are dissatisfied with the results of the restrictions, you may file a complaint with the customer center.'
          ]
        },
        {
          title: 'Article 10 (Protection of Personal Information)',
          content: 'The safe handling of your personal information is one of the Company\'s top priorities. Your personal information will only be used within the scope and purpose agreed upon for the smooth provision of services. Rest assured that the Company will never provide your personal information to third parties unless required by law or you have given separate consent. For more details about how the Company safeguards your personal information, please refer to the Agile Privacy Policy.'
        }
      ]
    },
    {
      id: 'chapter5',
      title: 'Chapter 5: Termination of Service Contract',
      articles: [
        {
          title: 'Article 11 (Termination of Service Contract)',
          content: [
            'If you no longer wish to use the Agile account, you may apply for termination at any time using the menu provided within the service, and the Company will promptly process your request in accordance with the law.',
            'If you have not logged in or accessed your Agile account service for a certain period as stipulated by law, the Company may destroy or separately store your Agile account information after notifying you via email, text message, or other valid means using the email address or phone number you registered. If essential information is lacking as a result, the service contract may be terminated. For more detailed information, please refer to the Agile Service Dormancy Policy in the Agile operating policy.',
            'Upon termination of the service contract, your Agile account information and individual service data linked to your Agile account will be deleted, except for cases where the retention of your information is required by law or the Privacy Policy. However, matters related to the deletion of posts or data created within individual services are subject to the terms of those individual services.',
            'Even after termination of the service contract, you may reapply for a new service contract with the Company.'
          ]
        },
        {
          title: 'Article 12 (Liability for Damages)',
          content: [
            'To the extent permitted by law, the Company does not make any specific commitments or warranties about the service, except as expressly stated in these Terms. The Company also does not guarantee the reliability or accuracy of information, materials, or facts posted in the service by Content Providers (CPs) or members. The Company is not liable for any damages you may suffer unless they are caused by the Company\'s negligence.',
            'The Company is not liable for damages arising from the following causes without the Company\'s negligence. To the extent permitted by law, the Company is also not liable for indirect, special, consequential, punitive, or exemplary damages:',
            'Damages caused by force majeure, such as natural disasters.',
            'Damages caused by your fault during the use of the service.',
            'Personal damages arising during access to or use of the service.',
            'Damages caused by illegal access to or use of the Company\'s server by third parties.',
            'Damages caused by interruptions in data transmission to or from the Company\'s server by third parties.',
            'Damages caused by the transmission or distribution of malicious programs by third parties.',
            'Damages caused by data omission, destruction, or defamation that occur during the use of the service by third parties.',
            'Other damages that occur without the Company\'s willful misconduct or negligence.'
          ]
        }
      ]
    },
    {
      id: 'chapter6',
      title: 'Chapter 6: Miscellaneous',
      articles: [
        {
          title: 'Article 13 (Dispute Resolution)',
          content: 'In the event of a dispute arising between the Company and members related to these Terms or the Agile account service, the Company and members shall make efforts to resolve the dispute through sincere consultation.'
        }
      ]
    }
  ];

  useEffect(() => {
    chapterRefs.current = chapterRefs.current.slice(0, chapters.length);
  }, [chapters]);

  const handleChapterClick = (index) => {
    chapterRefs.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <PageWrapper>
      <Content>
        <Title>Agile Account Terms and Conditions</Title>

        <TableOfContents>
          <TOCTitle>Table of Contents</TOCTitle>
          <TOCList>
            {chapters.map((chapter, index) => (
              <TOCItem key={chapter.id}>
                <TOCLink
                  href={`#${chapter.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleChapterClick(index);
                  }}
                >
                  {chapter.title}
                </TOCLink>
              </TOCItem>
            ))}
          </TOCList>
        </TableOfContents>

        {chapters.map((chapter, index) => (
          <Chapter
            key={chapter.id}
            id={chapter.id}
            ref={(el) => (chapterRefs.current[index] = el)}
          >
            <ChapterTitle>{chapter.title}</ChapterTitle>
            {chapter.articles.map((article, articleIndex) => (
              <Article key={articleIndex}>
                <ArticleTitle>{article.title}</ArticleTitle>
                {Array.isArray(article.content) ? (
                  <List>
                    {article.content.map((item, itemIndex) => (
                      <ListItem key={itemIndex}>{item}</ListItem>
                    ))}
                  </List>
                ) : (
                  <Paragraph>{article.content}</Paragraph>
                )}
              </Article>
            ))}
          </Chapter>
        ))}
      </Content>
    </PageWrapper>
  );
}

export default TermsOfService;