import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #1d1d1f;
  background-color: #fbfbfd;
  padding: 80px 20px;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
`;

const TableOfContents = styled.div`
  background-color: #f5f5f7;
  border-radius: 18px;
  padding: 30px;
  margin-bottom: 40px;
`;

const TOCTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const TOCList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TOCItem = styled.li`
  margin-bottom: 10px;
`;

const TOCLink = styled.a`
  color: #06c;
  text-decoration: none;
  font-size: 17px;

  &:hover {
    text-decoration: underline;
  }
`;

const Chapter = styled.div`
  margin-bottom: 60px;
`;

const ChapterTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
`;

const Article = styled.div`
  margin-bottom: 40px;
`;

const ArticleTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const List = styled.ol`
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
  padding-left: 30px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

function PrivacyPolicy() {
  const chapterRefs = useRef([]);

  const chapters = [
    {
      id: 'introduction',
      title: 'Introduction',
      articles: [
        {
          title: 'Privacy Policy of Agile',
          content: 'This "Privacy Policy" outlines the guidelines that Agile must follow to ensure users can safely use the service. Agile complies with relevant laws and regulations of the Republic of Korea, as well as personal data protection guidelines. We collect, use, and provide personal data based on user consent and are committed to providing an easy-to-understand policy to actively protect users\' rights.'
        }
      ]
    },
    {
      id: 'chapter1',
      title: 'Chapter 1: Definitions',
      articles: [
        {
          title: 'Article 1: Personal Data',
          content: [
            'Required Information: Email, password, username, profile picture, follower list, following list, contact details, service usage history, purchase and payment history, date of birth, gender.',
            'Processing Purpose: The personal data collected by Agile is used exclusively for member management, service provision and improvement, and new service development within Agile.',
            'Third-Party Provision: Agile does not provide personal data to third parties without explicit user consent, except as required by law. If a third-party provision is required, we notify the user of the recipient and purpose and obtain consent before proceeding.'
          ]
        },
        {
          title: 'Article 2: User Rights and Responsibilities',
          content: 'Users can view, modify, or withdraw their consent for the collection, use, or provision of personal data at any time. They can also request account deletion.'
        },
        {
          title: 'Article 3: Customer Support',
          content: 'Any inquiries, complaints, or suggestions regarding personal data protection during the use of Agile\'s services can be directed to Agile\'s Personal Data Protection Officer.'
        }
      ]
    },
    {
      id: 'chapter2',
      title: 'Chapter 2: Personal Data Collection',
      articles: [
        {
          title: 'Article 1: Data Collection Methods',
          content: [
            'We collect the minimum necessary personal data for service provision through user consent during account registration or service use.',
            'Data Collected During Account Creation:',
            'Required: Email, password, username, profile picture, follower list, following list, contact details, service usage, purchase and payment history, date of birth, gender.',
            'Data Collected During Identity Verification:',
            'Required: Name, gender, date of birth, mobile number, telecom provider, nationality, encrypted identification values (CI), duplicate membership confirmation information (DI).',
            'Data Collected During Paid Services:',
            'Required for credit card payments: Partial card number, card company.',
            'Required for mobile payments: Mobile number, payment approval number.',
            'Required for bank transfers: Account holder\'s name, account number, bank.'
          ]
        },
        {
          title: 'Article 2: Automatic Data Collection',
          content: 'During service use, device information, IP address, cookies, and service usage history may be automatically collected. This includes user-generated content, search terms, and interaction logs.'
        },
        {
          title: 'Article 3: Third-Party Data Collection',
          content: 'Agile may receive personal data from third parties, such as affiliated services, with the user\'s consent.'
        }
      ]
    },
    {
      id: 'chapter3',
      title: 'Chapter 3: Personal Data Use',
      articles: [
        {
          title: 'Article 1: Purpose of Data Use',
          content: [
            'Agile uses personal data for member identification, service provision and improvement, development of new services, and more. Specific uses include:',
            'Verification of user identity and age.',
            'Sending messages between users and managing friends.',
            'Providing personalized content, recommendations, events, and advertisements based on demographic characteristics.',
            'Processing payments for paid services.',
            'Preventing fraudulent or inappropriate use of the service.'
          ]
        },
        {
          title: 'Article 2: Additional Data Use',
          content: 'Personal data may be used or provided to third parties without consent if it is related to the original purpose and does not infringe on user rights. This will be done by considering relevant legal provisions and user benefit.'
        },
        {
          title: 'Article 3: Pseudonymized Data Processing',
          content: 'Collected data may be pseudonymized and used for statistical analysis, scientific research, or public record keeping. This pseudonymized data will be kept secure and separated from additional identifying information to prevent re-identification.'
        }
      ]
    },
    {
      id: 'chapter4',
      title: 'Chapter 4: Personal Data Provision',
      articles: [
        {
          title: 'Article 1: Third-Party Provision',
          content: [
            'Agile does not provide user personal data to third parties without prior consent unless required by law.',
            'Data may be provided to third-party services, partners, or foreign entities, but only with user consent and clear notification regarding the recipient and country.'
          ]
        },
        {
          title: 'Article 2: Emergency Situations',
          content: 'In cases of emergencies such as disasters, infectious diseases, or life-threatening situations, Agile may provide personal data to relevant authorities without user consent.'
        }
      ]
    },
    {
      id: 'chapter5',
      title: 'Chapter 5: Personal Data Destruction',
      articles: [
        {
          title: 'Article 1: Destruction Procedure',
          content: [
            'Personal data is destroyed without delay once the purpose of collection and use has been achieved. The method of destruction is determined based on the form of the data.',
            'For electronic files: Data is safely deleted to prevent recovery and reproduction.',
            'For records, printed materials, or written documents: These are shredded or incinerated.'
          ]
        },
        {
          title: 'Article 2: Information Retention and Destruction',
          content: [
            'Certain information may be retained for a specific period based on internal policies before being destroyed:',
            '1. Stored for up to 1 year from account deletion:',
            'Agile account and withdrawal notice email address (encrypted) for sending guide emails and handling customer service inquiries.',
            'Records of fraudulent service use.',
            '2. Rights infringement or harmful content reports: Retained for 5 years before destruction.',
            'Retention Period and Destruction: Personal data is destroyed after the retention period or once the purpose of collection has been achieved. Information that needs to be retained based on legal obligations will be stored separately and securely for the necessary period before destruction.'
          ]
        }
      ]
    },
    {
      id: 'chapter6',
      title: 'Chapter 6: Processing of Personal Location Information',
      articles: [
        {
          title: 'Article 1: Location Information Use',
          content: [
            'Agile processes personal location information in accordance with the Location Information Protection and Utilization Act.',
            'Personal location information may be retained to provide services based on location as per Agile\'s location-based service terms.'
          ]
        },
        {
          title: 'Article 2: Retention and Destruction of Location Information',
          content: [
            'Personal location information is destroyed without delay once the purpose for using the location-based service is fulfilled.',
            'If personal location information is stored with user-generated content such as posts or media, it is retained for the same duration as the related content.',
            'Personal location information is securely deleted to prevent recovery once the retention period is over unless legally required otherwise.'
          ]
        },
        {
          title: 'Article 3: Third-Party Provision of Location Information',
          content: [
            'Agile does not provide personal location information to third parties without the user\'s prior consent.',
            'If location information is provided to a third party, the user will be notified of the recipient, purpose, and timing of the provision and must consent in advance. Immediate notification is given for each instance of provision to third parties.'
          ]
        }
      ]
    },
    {
      id: 'chapter7',
      title: 'Chapter 7: User and Legal Representative Rights and Methods of Exercise',
      articles: [
        {
          title: 'Article 1: User Rights',
          content: [
            'Users may exercise the following rights regarding their personal data:',
            '1. Right to request access to personal data.',
            '2. Right to request correction of personal data.',
            '3. Right to request the suspension of personal data processing.',
            '4. Right to request deletion of personal data and withdraw consent or request account deletion.'
          ]
        },
        {
          title: 'Article 2: Exercise of Rights',
          content: 'Upon receiving a user\'s request, Agile will process it without delay. If a user requests the correction of errors in their personal data, Agile will not use or provide the personal data until the correction is completed.'
        }
      ]
    },
    {
      id: 'chapter8',
      title: 'Chapter 8: Information on Automatic Data Collection Devices (Installation, Operation, and Rejection)',
      articles: [
        {
          title: 'Article 1: Use of Cookies',
          content: 'Cookies may be installed and operated for providing web-based services. Cookies are used to support fast and convenient website usage and provide personalized services.'
        },
        {
          title: 'Article 2: What is a Cookie?',
          content: 'A cookie is a small text file sent to the user\'s browser from a website and stored on the user\'s device. Cookies store user information and settings, enabling personalized services and faster website navigation.'
        },
        {
          title: 'Article 3: Purpose of Using Cookies',
          content: 'Cookies store and retrieve information to offer personalized and customized services. When a user revisits the website, the server reads the cookies stored on the user\'s device to maintain settings and provide personalized services, including optimized advertisements based on user activity.'
        },
        {
          title: 'Article 4: Refusing Cookies',
          content: [
            'Users can choose whether to allow cookies. Cookie settings can be adjusted via the following browser paths:',
            'Chrome: Settings > Privacy and Security > Clear Browsing Data',
            'Edge: Settings > Cookies and Site Permissions > Manage and delete cookies and site data',
            'Mobile Browsers:',
            'Chrome: Settings > Privacy and Security > Clear Browsing Data',
            'Safari: Settings > Safari > Block all cookies',
            'Samsung Internet: Settings > Internet Usage History > Delete internet usage history'
          ]
        },
        {
          title: 'Article 5: Impact of Refusing Cookies',
          content: 'Refusing cookies may result in inconvenience while using the web service, and certain features that require login may be unavailable.'
        }
      ]
    },
    // ... (previous chapters remain the same)
  {
    id: 'chapter9',
    title: 'Chapter 9: Measures for Securing the Safety of Personal Data',
    articles: [
      {
        title: 'Article 1: Encryption of Personal Data',
        content: 'Agile encrypts users\' personal data. Personal data is transmitted through encrypted communication channels, and critical information such as passwords is stored in encrypted form.'
      },
      {
        title: 'Article 2: Protection Against Hacking and Viruses',
        content: [
          'To prevent the leakage or damage of users\' personal data due to hacking or viruses, Agile has established systems in areas controlled from external access.',
          'We operate intrusion detection and prevention systems that monitor and block unauthorized access 24/7.',
          'Anti-virus software is installed to prevent the system from being infected by malicious code or viruses, and we continuously research and apply the latest security technologies to our services.'
        ]
      },
      {
        title: 'Article 3: Minimization of Access to Personal Data',
        content: 'Access to personal data is limited to a minimum number of employees. Strict password policies and access rights management are applied to ensure that only authorized personnel can access the personal data processing system. Continuous audits are conducted to ensure compliance.'
      },
      {
        title: 'Article 4: Regular Training for Personal Data Handlers',
        content: 'Regular training sessions and awareness campaigns are conducted for all personnel involved in personal data processing to emphasize their duty to protect users\' personal data and follow security protocols.'
      },
      {
        title: 'Article 5: Secure Storage of Physical Records',
        content: 'Documents, auxiliary storage devices, or other media containing personal data are stored in secure locations with locking mechanisms to prevent unauthorized access.'
      },
      {
        title: 'Article 6: Compliance with GDPR',
        content: 'Agile complies with the General Data Protection Regulation (GDPR) and the laws of EU member states. If services are provided to users within the European Union, the following provisions apply:'
      },
      {
        title: 'Article 7: Legal Basis for Processing Personal Data',
        content: [
          'Personal data is processed solely for the purposes specified in "Chapter 3: Personal Data Use." Agile informs users and obtains their consent prior to processing. Under GDPR, Agile may process personal data under the following conditions:',
          '1. User consent',
          '2. Contractual necessity',
          '3. Compliance with legal obligations',
          '4. Protection of vital interests',
          '5. Legitimate interests of the company, provided they do not override the rights and freedoms of the data subject.'
        ]
      },
      {
        title: 'Article 8: Rights of Users in the European Union',
        content: [
          'Users in the EU have the right to request the transfer of their personal data to another controller and to object to the processing of their data. Users also have the right to file complaints with data protection authorities.',
          'Agile may use personal data for marketing purposes, such as event and advertisement delivery, but will always seek user consent in advance. Users can withdraw consent at any time.',
          'Any requests regarding these rights can be submitted via the customer service department by written request, phone, or email. Agile will process these requests promptly. If a user requests correction of personal data errors, the data will not be used or provided until the corrections are completed.'
        ]
      }
    ]
  },
  {
    id: 'chapter10',
    title: 'Chapter 10: Personal Data Protection Officer and Grievance Handling Department',
    articles: [
      {
        title: 'Article 1: Designation of Responsible Personnel',
        content: 'To handle user inquiries, complaints, and other matters related to personal data, Agile has appointed a Personal Data Protection Officer and designated a grievance handling department.'
      },
      {
        title: 'Article 2: Personal Data Protection Officer (DPO) and Grievance Handling Department',
        content: [
          'Officer: Yuk Seung Hyun (Personal Data Protection Officer / DPO / Location Information Manager)',
          'Department: Tech Department'
        ]
      }
    ]
  },
  {
    id: 'chapter11',
    title: 'Chapter 11: Obligation to Notify Changes and Amendments',
    articles: [
      {
        title: 'Article 1: Notification of Changes',
        content: [
          'Agile may revise this privacy policy to reflect changes in relevant laws or services. If any amendments are made, Agile will notify users at least 7 days prior to the changes.',
          'In the case of significant changes to users\' rights, Agile will notify users at least 30 days in advance.'
        ]
      },
      {
        title: 'Article 2: Commitment to User Privacy',
        content: 'Agile values the privacy of users\' information and is committed to making every effort to ensure that users can use the service with confidence.'
      }
    ]
  }
  ];

  useEffect(() => {
    chapterRefs.current = chapterRefs.current.slice(0, chapters.length);
  }, [chapters]);

  const handleChapterClick = (index) => {
    chapterRefs.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <PageWrapper>
      <Content>
        <Title>Agile Privacy Policy</Title>

        <TableOfContents>
          <TOCTitle>Table of Contents</TOCTitle>
          <TOCList>
            {chapters.map((chapter, index) => (
              <TOCItem key={chapter.id}>
                <TOCLink
                  href={`#${chapter.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleChapterClick(index);
                  }}
                >
                  {chapter.title}
                </TOCLink>
              </TOCItem>
            ))}
          </TOCList>
        </TableOfContents>

        {chapters.map((chapter, index) => (
          <Chapter
            key={chapter.id}
            id={chapter.id}
            ref={(el) => (chapterRefs.current[index] = el)}
          >
            <ChapterTitle>{chapter.title}</ChapterTitle>
            {chapter.articles.map((article, articleIndex) => (
              <Article key={articleIndex}>
                <ArticleTitle>{article.title}</ArticleTitle>
                {Array.isArray(article.content) ? (
                  <List>
                    {article.content.map((item, itemIndex) => (
                      <ListItem key={itemIndex}>{item}</ListItem>
                    ))}
                  </List>
                ) : (
                  <Paragraph>{article.content}</Paragraph>
                )}
              </Article>
            ))}
          </Chapter>
        ))}
      </Content>
    </PageWrapper>
  );
}

export default PrivacyPolicy;