import  { useState } from "react";
import styled from "styled-components";

// Styled Components
const PageWrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #1d1d1f;
  background-color: #fbfbfd;
  padding: 80px 20px;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
`;

const FAQSection = styled.div`
  margin: 20px 0;
`;

const Question = styled.div`
  background-color: #ffffff;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }
`;

const Answer = styled.div`
  padding: 10px 20px;
  background-color: #f9f9fb;
  border-left: 4px solid #1d1d1f;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
`;

const ImageGallery = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 40px 0;
`;

const Image = styled.img`
  height: 500px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const SupportSection = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const SupportTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const SupportText = styled.p`
  font-size: 16px;
  color: #555;
`;

// Main Support Component
function Support() {
  const [openIndex, setOpenIndex] = useState<
    number | null
  >(null);
  const faqs = [
    { 
      question: "What should I do if I forgot my password?", 
      answer: "Click 'Forgot Password' on the login page and enter your email address. You will receive a password reset link via email. This process works for both the web and the app."
    },
    { 
      question: "How can I delete my account?", 
      answer: "To delete your account, navigate to Profile > Settings > User Information in the app, or use the website. Once deleted, basic user data will be deleted immediately, user-created content will be removed, and interactions with other users will be anonymized. If you have an active subscription, cancel it before deleting your account."
    },
    { 
      question: "How can I cancel my subscription?", 
      answer: "To cancel your subscription, use the subscription management features on your mobile device: Apple Users can manage subscriptions via the App Store or iPhone settings. Google Users can manage subscriptions via the Google Play Store."
    },
    { 
      question: "How can I contact you for business inquiries?", 
      answer: "For business-related inquiries, please contact us at business@interultimate.com."
    },
    { 
      question: "How can I contact you for other inquiries?", 
      answer: "For any other service-related questions, please email us at support@interultimate.com. We will respond as soon as possible during our business hours."
    }
  ];

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <PageWrapper>
      <Content>
        <Title>Support</Title>

        <ImageGallery>
          <Image src="support.png"/>
        </ImageGallery>

        <FAQSection>
          <h3>Frequently asked Questions</h3>
          {faqs.map((faq, index) => (
            <div key={index}>
              <Question onClick={() => toggleFAQ(index)}>
                <span>{faq.question}</span>
                <span>{openIndex === index ? "▲" : "▼"}</span>
              </Question>
              {openIndex === index && <Answer>{faq.answer}</Answer>}
            </div>
          ))}
        </FAQSection>


        <SupportSection>
          <SupportTitle>Get Support</SupportTitle>
          <SupportText>
          We’re here to help! Please share a few details, and we’ll provide the most suitable solution for your needs.
          You can reach us via email.
          </SupportText>
          <SupportText>Email: <a href="mailto:support@interultimate.com">support@interultimate.com</a></SupportText>
        </SupportSection>
      </Content>
    </PageWrapper>
  );
}

export default Support;